import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LanguageList from '../../../../SharedComponents/LanguageList';
import SectionHeader from '../SectionHeader';
import AddCategoryAgenda from './AddCategoryAgenda';
import CategoryAgenda from './CategoryAgenda';

export default function Agenda(props) {
  const { section, languages, provided } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [languageId, setLanguageId] = useState(1);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setLanguageId(languages?.data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  }, [languages.data]);
  return (
    <>
      <div className="block bg-white w-full shadow border mb-4">
        <SectionHeader
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          section={section}
          languageId={languageId}
          provided={provided}
        />
        <Formik enableReinitialize initialValues={section}>
          {({ values, setFieldValue, setValues }) => {
            return (
              <>
                <div className={`bg-white px-4 overflow-hidden ${isOpen ? 'py-3 h-full  ' : '  py-0 h-0'}`}>
                  <div>
                    <div className="flex justify-between relative items-center">
                      <div className="flex transform -translate-y-1 relative z-10">
                        {t('Section Type')} : {t('Agenda')}
                      </div>
                      <div>
                        <div>
                          <LanguageList
                            languages={languages.data?.data}
                            languageId={languageId}
                            setLanguageId={setLanguageId}
                            className="flex justify-end transform translate-y-1 relative z-10 mb-4"
                          />
                        </div>
                      </div>
                      <hr className="border w-full absolute top-0 left-0 z-0 mt-11" />
                    </div>
                  </div>
                  <div>
                    <AddCategoryAgenda
                      languageId={languageId}
                      languages={languages.data?.data}
                      pgSectionCategoryId={values.pgSectionCategoryId}
                    />
                    {/* <AddListParent pgSectionListId={values.pgSectionListId} addListMutation={addListMutation} /> */}
                  </div>
                  {values.pgSectionCategory.pgSectionCategoryAgendas.map((item, index) => {
                    return (
                      <CategoryAgenda
                        languages={languages.data?.data}
                        languageId={languageId}
                        initValues={item}
                        key={index}
                      />
                    );
                  })}
                  {/* {values &&
                    values.pgSectionList &&
                    values.pgSectionList.pgSectionListParents.map((item) => {
                      return (
                        <ListParent
                          key={item.id}
                          setFieldValue={setFieldValue}
                          languageId={languageId}
                          pgSectionListParents={item}
                        />
                      );
                    })} */}
                </div>
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
}

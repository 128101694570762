import axios from 'axios';
import { Field, FieldArray, Formik } from 'formik';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

export default function CategoryAgenda(props) {
  const { initValues, languageId, languages } = props;
  const { t, i18n } = useTranslation();

  const pgSectionCategoryAgendaItemsLngs = languages.map((language) => {
    return {
      languageId: language.id,
      title: 'Agenda Name',
      instractorName: 'instuctor name',
    };
  });
  const pgSectionCategoryAgendaItemsJSON = {
    timeFrom: '',
    timeTo: '',
    videoId: 0,
    videoUrl: 'string',
    pgSectionCategoryAgendaItemsLngs: pgSectionCategoryAgendaItemsLngs,
  };
  const editCategoryAgendaMutation = useMutation(editCategoryAgenda, {
    onSuccess: () => {},
  });

  const onSubmit = (e) => {
    console.log('e', e);
    editCategoryAgendaMutation.mutate(e);
  };

  return (
    <>
      <div className="mt-2 ">
        <Formik onSubmit={onSubmit} enableReinitialize initialValues={initValues}>
          {({ values, setFieldValue, setValues, errors }) => {
            console.log('values', values);
            console.log('values', errors);
            return (
              <>
                {' '}
                <div>
                  <FieldArray
                    name="pgSectionCategoryAgendaLngs"
                    render={(arrayHelpers) => (
                      <>
                        {values.pgSectionCategoryAgendaLngs.map((e, index) => {
                          return (
                            <Fragment key={index + 100}>
                              {languageId === e.languageId && (
                                <>
                                  <label>
                                    <Field
                                      className="border rounded  w-full  p-2 mt-2 "
                                      type="text"
                                      placeholder={t('Add Title')}
                                      name={`pgSectionCategoryAgendaLngs[${index}].title`}
                                    />
                                  </label>
                                </>
                              )}
                            </Fragment>
                          );
                        })}
                      </>
                    )}
                  />
                  <FieldArray
                    name="pgSectionCategoryAgendaItems"
                    render={(arrayHelpers) => (
                      <>
                        {values.pgSectionCategoryAgendaItems.map((e, index) => {
                          return (
                            <Fragment key={index + 200}>
                              <div className="flex space-x-2">
                                <label>
                                  <Field
                                    className="border rounded  w-full  p-2 mt-2 "
                                    type="time"
                                    placeholder={t('Time From')}
                                    name={`pgSectionCategoryAgendaItems[${index}].timeFrom`}
                                  />
                                </label>
                                <label>
                                  <Field
                                    className="border rounded  w-full  p-2 mt-2 "
                                    type="time"
                                    placeholder={t('Time To')}
                                    name={`pgSectionCategoryAgendaItems[${index}].timeTo`}
                                  />
                                </label>
                                <ParentField
                                  generalIndex={index}
                                  name={`pgSectionCategoryAgendaItems[${index}].pgSectionCategoryAgendaItemsLngs`}
                                  values={values}
                                  languageId={languageId}
                                />
                              </div>
                            </Fragment>
                          );
                        })}
                        <button type="button" onClick={() => arrayHelpers.push(pgSectionCategoryAgendaItemsJSON)}>
                          Add Row
                        </button>
                      </>
                    )}
                  />
                </div>
                <button
                  type="button"
                  onClick={() => {
                    onSubmit(values);
                  }}
                >
                  Save Agenda Item
                </button>
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
}

const ParentField = (props) => {
  const { values, name, languageId, generalIndex } = props;
  const { t, i18n } = useTranslation();

  return (
    <>
      <FieldArray
        name={name}
        render={(arrayHelpers) => (
          <>
            {values.pgSectionCategoryAgendaItems[generalIndex]?.pgSectionCategoryAgendaItemsLngs?.map((e, index) => {
              return (
                <Fragment key={index}>
                  {languageId === e.languageId && (
                    <>
                      <label>
                        <Field
                          className="border rounded  w-full  p-2 mt-2 "
                          type="text"
                          placeholder={t('Add Title')}
                          name={`pgSectionCategoryAgendaItems[${generalIndex}].pgSectionCategoryAgendaItemsLngs[${index}].title`}
                        />
                      </label>
                      <label>
                        <Field
                          className="border rounded  w-full  p-2 mt-2 "
                          type="text"
                          placeholder={t('Add Title')}
                          name={`pgSectionCategoryAgendaItems[${generalIndex}].pgSectionCategoryAgendaItemsLngs[${index}].instractorName`}
                        />
                      </label>
                    </>
                  )}
                </Fragment>
              );
            })}
          </>
        )}
      />
    </>
  );
};

export const editCategoryAgenda = async (e) => {
  const data = await axios.post('/PgSection/EditCategoryAgenda', e);
  return data;
};
